import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import Slider, { Settings } from "react-slick";

export type CarouselProps = {
	children: ReactNode;
	slideToShow?: number;
	type: "single" | "multiple";
	scrollBehaviour?: "drag" | "arrow";
} & Settings;

const Carousel: FC<CarouselProps> = (props) => {
	const { children, type, scrollBehaviour = "drag", slideToShow } = props;
	const [sliderAction, setSliderAction] = useState<Slider>();
	const sliderRef = useRef<Slider>(null);

	useEffect(() => {
		if (sliderRef.current) {
			setSliderAction(sliderRef.current);
		}
	}, []);

	const sliderConfig: Record<CarouselProps["type"], Settings> = {
		single: {
			dots: true,
			arrows: false,
			fade: true,
			infinite: true,
			speed: 2000,
			slidesToShow: 1,
			slidesToScroll: 1,
			swipeToSlide: scrollBehaviour === "drag",
			adaptiveHeight: false,
			dotsClass: classNames(
				"slick-dots",
				"[&>li>button::before]:!text-white",
				"[&>li>button::before]:!text-base",
			),
			appendDots: (dots) => (
				<div style={{ width: "fit-content", right: 15, bottom: 20 }}>{dots}</div>
			),
		},
		multiple: {
			dots: false,
			arrows: false,
			infinite: true,
			speed: 500,
			slidesToShow: slideToShow && slideToShow <= 4 ? slideToShow : 4,
			slidesToScroll: 1,
			initialSlide: 0,
			autoplay: false,
			swipeToSlide: scrollBehaviour === "drag",
			draggable: false,
			responsive: [
				{
					breakpoint: 1440,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						initialSlide: 1,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						initialSlide: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		},
	};

	return (
		<div
			className={classNames({
				relative: true,
				"mx-4": type === "multiple",
			})}
		>
			<div
				className="absolute -left-6 top-2/4 translate-x-0 -translate-y-2/4 cursor-pointer"
				hidden={type !== "multiple"}
			>
				<span onClick={() => sliderAction?.slickPrev()}>
					<ChevronLeftIcon width={32} height={32} color="#000" />
				</span>
			</div>

			<Slider {...sliderConfig[type]} ref={sliderRef}>
				{children}
			</Slider>

			<div
				className="absolute -right-6 top-2/4 translate-x-0 -translate-y-2/4 cursor-pointer"
				hidden={type !== "multiple"}
			>
				<span onClick={() => sliderAction?.slickNext()}>
					<ChevronRightIcon width={32} height={32} color="#000" />
				</span>
			</div>
		</div>
	);
};

export default Carousel

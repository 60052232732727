import { ThemeConfig } from "antd";

export const colorPalette = {
	sacramento: "rgba(0, 106, 72, 1)",
	sacramento_100: "rgba(0, 106, 72, 0.1)",
	sacramento_500: "rgba(0, 66, 46, 1)",
	canada: "rgba(239, 255, 254, 1)",
	sand: "rgba(231, 221, 207, 1)",
	sand_400: "rgba(231, 221, 207, 0.4)",
	bulrush: "rgba(109, 86, 54, 1)",
	brows: "rgba(57, 45, 28, 1)",
};

export const theme: ThemeConfig = {
	token: {
		colorPrimary: colorPalette.bulrush,
	},
	components: {
		Menu: {
			itemSelectedColor: colorPalette.bulrush,
			itemSelectedBg: colorPalette.sand,
			itemHoverBg: colorPalette.sand_400,
			itemHoverColor: colorPalette.bulrush,
			// lineType: "hidden",
		},
		Button: {
			boxShadow: "none",
		},
	},
};

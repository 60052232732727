import { ReactNode, useState } from "react";
import { NavigationContext, NavigationLayoutProps } from ".";

function NavigationProvider(props: { children: ReactNode }) {
	const [search, setSearch] = useState("");
	const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
	const [navigationLayout, setNavigationLayout] = useState<NavigationLayoutProps>({ defaultSearchValue: search, searchVariant: "search" });
	return (
		<NavigationContext.Provider
			value={
				navigationLayout.searchVariant === "search"
					? {
							showSearch: navigationLayout.showSearch,
							onChangeText: setSearch,
							searchVariant: navigationLayout.searchVariant,
							search,
							navigationLayout,
							onChangeNavigationLayout(params) {
								setNavigationLayout(params);
								setSearch(params.defaultSearchValue ?? "");
							},
						}
					: navigationLayout.searchVariant === "searchWithFilters"
						? {
								onChangeText: setSearch,
								showSearch: navigationLayout.showSearch,
								searchVariant: navigationLayout.searchVariant,
								navigationLayout,
								onChangeNavigationLayout(params) {
									setNavigationLayout(params);
									setSearch(params.defaultSearchValue ?? "");
								},
								search,
								filter: {
									value: selectedFilter,
									onChange: setSelectedFilter,
									options: navigationLayout.options,
									disabled: navigationLayout.disabled,
								},
							}
						: undefined
			}
		>
			{props.children}
		</NavigationContext.Provider>
	);
}

export default NavigationProvider;

import { Helmet } from "react-helmet-async";

export type MetaTagProps = {
	description: string;
	indexing?: { follow: boolean };
};

function MetaTag({ description, indexing }: MetaTagProps) {
	return (
		<Helmet>
			{/* Standard metadata tags */}
			<meta name="description" content={description} />
			{indexing && <meta name="robots" content={`index, ${indexing.follow ? "follow" : "nofollow"}`} />}
			<link rel="canonical" href={window.location.href} />
		</Helmet>
	);
}

export default MetaTag;

import {
	QueryFunction,
	QueryKey,
	UseQueryOptions,
	UseQueryResult,
	useQuery,
	parseQueryArgs,
} from "@tanstack/react-query";

export const noRetchDefault = {
	cacheTime: 0,
	refetchInterval: false,
	refetchIntervalInBackground: false,
	refetchOnReconnect: false,
	refetchOnWindowsFocus: false,
	refetchOnMount: false,
} as const;

export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "initialData"> & {
		initialData?: () => undefined;
	},
): UseQueryResult<TData, TError>;

export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "initialData"> & {
		initialData?: TQueryFnData | (() => TQueryFnData);
	},
): UseQueryResult<TData, TError>;

export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError>;

export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	options: Omit<
		UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
		"queryKey" | "initialData"
	> & {
		initialData?: () => undefined;
	},
): UseQueryResult<TData, TError>;

export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	options: Omit<
		UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
		"queryKey" | "initialData"
	> & {
		initialData?: TQueryFnData | (() => TQueryFnData);
	},
): UseQueryResult<TData, TError>;

export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryKey">,
): UseQueryResult<TData, TError>;

export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options: Omit<
		UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
		"queryFn" | "queryKey" | "initialData"
	> & {
		initialData?: () => undefined;
	},
): UseQueryResult<TData, TError>;

export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options: Omit<
		UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
		"queryFn" | "queryKey" | "initialData"
	> & {
		initialData?: TQueryFnData | (() => TQueryFnData);
	},
): UseQueryResult<TData, TError>;

export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryFn" | "queryKey">,
): UseQueryResult<TData, TError>;

export function useQueryNoRefetch<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	arg1: TQueryKey | UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
	arg2?:
		| QueryFunction<TQueryFnData, TQueryKey>
		| UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
	arg3?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> {
	const parsedOptions = parseQueryArgs(arg1, arg2, arg3);
	return useQuery({
		...noRetchDefault,
		//overring defaults
		...parsedOptions,
	});
}

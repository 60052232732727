import { Button } from "antd";
import { FallbackProps } from "react-error-boundary";

function Fallback({ error, resetErrorBoundary }: FallbackProps) {
	return (
		<div role="alert" className="h-screen flex justify-center items-center">
			<div>
				<p className="text-xl font-semibold text-center">Something went wrong</p>
				<pre className="text-center mb-2">{error}</pre>
				<Button onClick={resetErrorBoundary} className="w-full">reset</Button>
			</div>
		</div>
	);
}

export default Fallback;

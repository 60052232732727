import { CalendarDaysIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import hall from "../../assets/images/hall.jpg";
import { useScrollToSmooth } from "@/utils";
import MetaTag from "@/components/MetaTag";

const Contattaci = () => {
	const { t } = useTranslation();

	useScrollToSmooth(0);

	return (
		<>
			<MetaTag description={t("contacts.description.seo")} indexing={{ follow: false }} />
			<section data-role="contacs" className="contattaci">
				<div
					className="py-8 min-h-[calc(100dvh_-_236px)] md:rounded"
					style={{
						backgroundImage: `url(${hall})`,
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						backgroundSize: "cover",
					}}
				>
					<div className="z-10 w-11/12 sm:7/12 lg:w-9/12 mx-auto flex flex-col space-y-8">
						<h1 className="bg-white rounded-md p-4 h-fit w-full md:w-fit">{t("contacts.title")}</h1>
						<p className="ml-auto bg-white rounded-md p-4 h-fit w-full md:max-w-[50%]">{t("contacts.description")}</p>
						<div className="mr-auto bg-white rounded-md p-4 h-fit w-full md:max-w-[50%]">
							<h3 className="font-semibold">Indirizzo:</h3>
							<div className="flex gap-2">
								<MapPinIcon width={16} height={16} />
								<a href="https://goo.gl/maps/ximwNtRupFvBD4Bg8" className="underline font-semibold" target="_blank" rel="noopener noreferrer">
									Via Gabrio Piola, 11, 20833 Giussano MB
								</a>
							</div>
						</div>
						<div className="mr-auto bg-white rounded-md p-4 h-fit w-full md:max-w-[50%]">
							<h3 className="font-semibold">Telefono:</h3>
							<div className="flex gap-2">
								<PhoneIcon width={16} height={16} />
								<a href="tel:+390362850600" className="underline font-semibold">
									+39 036 285 0600
								</a>
							</div>
						</div>
						<div className="ml-auto bg-white rounded-md p-4 h-fit w-full md:max-w-[50%]">
							<h3 className="font-semibold">Orari:</h3>
							<div className="flex gap-2 flex-wrap">
								<CalendarDaysIcon width={16} height={16} />
								<strong>Lunedi</strong>-<strong>Sabato</strong> <span>08-13.00 15-19:30</span>
							</div>
							<div className="flex gap-2 flex-wrap">
								<CalendarDaysIcon width={16} height={16} />
								<strong>Domenica</strong> <span>09-12.30 15-19:00</span>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Contattaci;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import it from "./languages/it.json";
import { unpromisify } from "@/utils/promises";

const resources = {
	it: { translation: it },
};

function initI18n() {
	unpromisify(async () => {
		await i18n.use(initReactI18next).init({
			resources,
			lng: "it",
			interpolation: {
				escapeValue: false, // react already safes from xss
			},
		});
	})();
}

export default initI18n;
